import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 962.000000 924.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,924.000000) scale(0.100000,-0.100000)">
					<path d="M4750 9206 c-32 -21 -143 -85 -320 -186 -36 -20 -150 -86 -255 -147
-104 -60 -296 -171 -425 -245 -129 -75 -307 -177 -395 -228 -88 -51 -248 -143
-355 -205 -107 -62 -212 -122 -232 -135 l-38 -22 0 -1173 0 -1174 -62 -34
c-35 -18 -151 -84 -258 -147 -107 -62 -283 -163 -390 -225 -195 -112 -296
-170 -485 -280 -55 -32 -174 -101 -265 -153 -91 -52 -223 -128 -295 -170 -71
-41 -160 -92 -197 -114 l-68 -39 0 -1189 0 -1190 38 -24 c20 -13 141 -84 267
-156 225 -129 484 -279 755 -435 1035 -598 1000 -579 1030 -568 23 8 583 330
1270 728 512 297 743 428 754 428 6 0 97 -50 201 -110 105 -61 233 -135 285
-165 297 -172 442 -256 557 -322 70 -40 172 -98 225 -130 179 -103 291 -168
493 -284 110 -63 209 -121 220 -128 11 -8 24 -14 31 -14 6 0 136 73 290 162
153 88 308 178 344 198 36 20 137 78 225 129 88 51 221 128 295 171 74 43 311
179 525 303 l390 226 0 1193 0 1192 -225 131 c-198 116 -838 485 -1420 820
-107 62 -238 138 -290 168 l-95 55 -5 1162 -5 1163 -155 89 c-85 49 -200 115
-255 147 -55 33 -275 160 -490 284 -214 123 -435 250 -490 282 -55 32 -174
101 -265 153 -91 52 -218 126 -283 163 -65 38 -123 69 -130 68 -7 0 -28 -10
-47 -23z m214 -160 c88 -51 339 -196 556 -321 217 -125 418 -240 445 -256 28
-16 223 -130 435 -252 l385 -222 3 -1142 2 -1141 -37 -22 c-21 -13 -112 -66
-203 -118 -91 -52 -221 -127 -290 -167 -211 -122 -320 -185 -455 -263 -212
-122 -446 -257 -685 -396 -124 -71 -247 -142 -275 -157 l-50 -27 -130 75
c-416 241 -643 372 -1035 598 -113 65 -248 143 -300 173 -52 31 -124 72 -160
92 -36 20 -130 75 -210 121 l-145 85 -3 1141 -2 1141 27 18 c16 10 84 50 153
89 69 40 211 121 315 182 105 60 244 140 310 178 66 38 167 96 225 130 58 34
202 117 320 185 118 68 310 179 425 246 116 68 212 123 214 123 2 1 76 -41
165 -93z m1993 -3469 c60 -35 322 -187 583 -337 261 -150 500 -288 530 -306
85 -51 733 -423 741 -426 4 -2 9 -14 10 -28 1 -14 1 -532 1 -1152 l-2 -1126
-147 -85 c-278 -160 -788 -454 -888 -512 -123 -71 -632 -365 -877 -506 l-68
-39 -67 38 c-89 51 -382 220 -503 290 -52 31 -124 72 -160 92 -36 20 -139 79
-230 132 -289 168 -720 417 -877 506 l-152 87 2 1144 2 1143 130 75 c72 42
281 162 465 268 184 106 376 217 425 245 83 49 174 101 675 390 102 59 208
121 235 138 28 17 53 31 57 31 4 1 56 -28 115 -62z m-4098 4 c36 -22 244 -141
461 -266 217 -125 451 -260 520 -300 69 -40 305 -177 525 -303 l400 -230 3
-1145 2 -1144 -147 -86 c-442 -254 -549 -316 -663 -382 -818 -473 -1171 -675
-1180 -675 -5 0 -150 81 -322 181 -172 100 -416 241 -543 314 -126 73 -318
183 -425 245 -107 62 -283 163 -390 225 -107 62 -217 126 -245 142 l-50 29 -3
1151 -2 1150 122 71 c68 38 195 111 283 162 172 100 456 264 650 375 66 38
167 96 225 130 58 34 202 117 320 185 118 68 249 143 290 167 41 24 81 43 89
43 8 0 44 -18 80 -39z"/>
					<path d="M4665 8923 c-322 -188 -624 -363 -665 -386 -48 -27 -415 -238 -490
-282 -25 -14 -119 -69 -210 -121 -91 -52 -172 -98 -180 -103 -8 -6 -51 -31
-95 -57 -44 -26 -83 -52 -87 -58 -11 -17 -10 -2100 1 -2129 7 -18 103 -78 388
-242 208 -120 398 -229 423 -243 25 -13 50 -29 56 -33 6 -5 42 -26 80 -47 38
-22 130 -74 204 -117 74 -43 164 -95 200 -115 102 -57 257 -148 346 -201 45
-27 86 -49 90 -49 5 0 20 -9 34 -20 38 -30 32 -33 208 73 26 15 56 32 67 37
11 6 38 21 60 34 144 84 561 325 615 355 36 21 142 81 235 135 94 54 202 117
240 139 39 22 90 52 115 67 25 15 118 69 208 121 l162 93 0 1072 0 1071 -40
27 c-22 14 -42 26 -46 26 -3 0 -18 8 -32 19 -15 10 -63 39 -107 63 -44 25
-188 107 -320 183 -132 77 -264 153 -293 169 -30 17 -61 36 -71 41 -9 6 -55
32 -101 59 -47 27 -121 70 -165 96 -44 26 -100 59 -125 72 -25 14 -133 76
-240 138 -190 110 -332 191 -334 189 0 0 -59 -34 -131 -76z m947 -1125 c71
-24 108 -55 137 -117 l26 -56 3 -765 c2 -540 0 -783 -8 -825 -18 -92 -49 -138
-117 -172 l-58 -28 -780 -3 c-545 -2 -795 0 -830 8 -105 23 -165 91 -184 210
-7 41 -11 326 -11 780 0 573 3 728 14 781 28 131 80 177 230 199 28 5 384 7
791 6 647 -2 746 -4 787 -18z"/>
					<path d="M4049 7720 c-93 -16 -131 -47 -154 -125 -14 -49 -15 -141 -13 -798
l3 -742 25 -44 c14 -25 41 -54 60 -65 34 -21 47 -21 815 -21 881 0 829 -5 876
78 l24 42 0 780 0 780 -23 37 c-14 22 -41 46 -70 60 l-47 23 -725 2 c-399 0
-746 -2 -771 -7z m513 -188 c9 -20 29 -64 43 -97 15 -33 45 -97 66 -142 21
-46 39 -85 39 -87 0 -3 34 -76 75 -162 41 -86 75 -159 75 -161 0 -2 13 -32 29
-66 16 -34 55 -116 85 -182 l56 -120 0 358 0 357 -40 0 -40 0 0 170 0 170 250
0 250 0 0 -170 0 -170 -40 0 -40 0 0 -411 0 -411 40 4 40 4 0 -173 0 -173
-209 0 -208 0 -43 93 c-24 50 -61 130 -82 177 -22 47 -47 103 -58 125 -82 175
-230 496 -262 570 -22 50 -42 92 -44 94 -2 2 -4 -158 -4 -357 l0 -362 43 0 42
0 0 -168 -1 -167 -249 0 -250 0 0 170 0 170 38 -3 37 -4 0 411 0 411 -40 0
-40 0 0 170 0 170 213 0 212 -1 17 -37z"/>
					<path d="M6790 5400 c-25 -15 -94 -55 -155 -90 -60 -34 -135 -77 -165 -95 -30
-17 -120 -69 -200 -115 -80 -45 -170 -97 -200 -115 -30 -18 -71 -42 -90 -53
-100 -56 -758 -436 -842 -486 l-98 -57 0 -1038 0 -1037 86 -50 c94 -53 103
-59 214 -123 129 -76 249 -145 435 -251 99 -57 191 -110 205 -119 14 -10 36
-22 50 -29 14 -7 39 -20 55 -31 17 -10 118 -69 225 -130 107 -62 268 -156 358
-208 116 -66 169 -92 183 -88 11 4 135 74 277 157 141 83 280 163 307 178 61
34 432 248 495 285 25 14 119 69 210 121 91 52 172 98 180 104 8 5 84 49 167
97 l153 88 0 1036 0 1037 -92 53 c-51 30 -101 58 -110 64 -9 5 -29 17 -45 26
-201 114 -781 450 -790 458 -7 6 -15 11 -18 11 -4 0 -34 16 -68 36 -73 43 -92
54 -217 126 -52 30 -169 97 -260 150 -91 52 -174 100 -185 105 -16 8 -30 5
-65 -17z m936 -1042 c69 -21 100 -43 129 -92 43 -74 46 -134 43 -931 -3 -799
-2 -775 -52 -852 -12 -18 -45 -44 -76 -60 l-55 -28 -785 -3 c-884 -3 -875 -4
-944 65 -73 74 -70 39 -74 888 -3 798 -1 842 41 917 35 63 111 101 222 111 22
3 369 3 770 2 628 -1 737 -3 781 -17z"/>
					<path d="M6190 4284 c-14 -3 -40 -7 -58 -10 -53 -8 -109 -66 -121 -125 -7 -33
-11 -296 -11 -773 0 -616 2 -730 15 -773 18 -59 56 -97 115 -113 26 -7 289
-10 792 -8 861 3 814 -1 859 86 l24 47 0 765 0 765 -23 46 c-17 34 -34 51 -70
70 l-47 24 -725 1 c-399 1 -736 0 -750 -2z m901 -180 c316 -96 499 -397 475
-784 -13 -223 -105 -427 -238 -531 -24 -19 -45 -37 -48 -41 -8 -11 -120 -64
-174 -82 -81 -27 -235 -37 -556 -34 l-305 3 -3 168 -2 167 40 0 40 0 0 410 0
410 -40 0 -40 0 0 171 0 171 393 -4 c355 -4 398 -6 458 -24z"/>
					<path d="M6660 3380 l0 -410 133 0 c157 0 219 15 290 68 89 68 136 165 144
298 14 212 -70 374 -223 432 -48 19 -77 22 -200 22 l-144 0 0 -410z"/>
					<path d="M2755 5451 c-6 -5 -118 -71 -250 -146 -132 -76 -280 -162 -330 -190
-49 -29 -139 -81 -200 -115 -60 -35 -126 -73 -145 -85 -19 -13 -60 -35 -90
-51 -30 -16 -59 -33 -65 -37 -11 -9 -235 -138 -532 -307 l-202 -115 -1 -1068
c0 -1008 1 -1068 18 -1074 9 -4 61 -33 114 -64 53 -32 111 -67 130 -77 181
-103 519 -298 540 -313 15 -10 29 -19 32 -19 4 0 137 -76 311 -178 33 -19 89
-52 125 -72 36 -20 180 -103 320 -184 l254 -146 56 34 c30 18 147 87 260 151
113 65 264 152 335 193 72 41 165 95 208 119 43 24 110 63 150 87 69 41 160
94 257 149 140 79 492 283 533 309 l47 30 0 1052 c0 578 -4 1056 -8 1062 -6 9
-245 150 -412 243 -25 14 -47 27 -50 30 -3 3 -30 19 -60 35 -30 16 -71 38 -90
51 -33 21 -121 71 -360 207 -58 33 -130 75 -160 93 -30 18 -163 95 -295 170
-132 76 -253 146 -270 156 -16 10 -39 23 -50 28 -11 6 -33 19 -50 30 -32 22
-55 26 -70 12z m833 -1124 c70 -27 103 -56 131 -116 l26 -56 0 -795 c0 -874 2
-851 -61 -920 -71 -78 -30 -75 -924 -75 -625 0 -804 3 -838 13 -58 17 -113 69
-137 128 -19 47 -20 78 -23 798 -3 758 0 853 33 917 37 70 105 108 214 119 42
4 405 7 806 6 658 -2 734 -4 773 -19z"/>
					<path d="M2018 4250 c-62 -11 -121 -45 -135 -78 -32 -74 -34 -121 -31 -852 3
-795 2 -774 57 -824 49 -45 54 -45 843 -46 808 0 815 0 859 53 50 60 50 60 47
868 l-3 754 -23 42 c-27 51 -58 69 -143 82 -75 13 -1399 13 -1471 1z m1402
-320 l0 -170 -244 0 -243 0 -7 -31 c-8 -41 -8 -683 0 -743 l7 -46 249 0 c224
0 250 -2 244 -16 -3 -9 -6 -85 -6 -170 l0 -154 -665 0 -665 0 0 170 0 170 248
2 247 3 3 408 2 407 -250 0 -250 0 0 170 0 170 665 0 665 0 0 -170z"/>
					<path d="M0 348 c0 -189 4 -338 8 -333 4 6 31 30 59 54 l53 43 0 175 0 175
146 -176 146 -176 44 0 44 0 0 290 0 290 -60 0 -60 0 -2 -176 -3 -175 -103
124 c-56 69 -114 138 -128 153 -14 16 -32 39 -41 52 -12 17 -25 22 -59 22
l-44 0 0 -342z"/>
					<path d="M662 663 c-77 -110 -72 -91 -72 -263 l0 -158 44 -63 43 -64 169 0
169 0 42 64 43 64 -1 161 0 161 -43 60 -43 60 -165 3 -166 2 -20 -27z m326
-260 l-3 -168 -140 0 -140 0 -3 155 c-1 85 0 160 2 167 4 10 40 13 146 13
l140 0 -2 -167z"/>
					<path d="M1660 355 c0 -185 4 -335 8 -333 5 2 32 23 60 47 l52 45 0 190 0 191
78 -155 77 -155 35 0 35 0 75 147 c41 81 77 142 81 135 3 -7 6 -90 5 -184 l-1
-173 58 0 57 0 0 290 0 291 -67 -3 -66 -3 -86 -172 c-47 -95 -88 -173 -91
-173 -3 0 -44 78 -91 173 l-85 172 -67 3 -67 3 0 -336z"/>
					<path d="M2378 355 c-2 -184 -2 -335 0 -335 6 0 96 74 104 85 4 6 8 139 8 298
l0 287 -55 0 -54 0 -3 -335z"/>
					<path d="M2580 684 c0 -3 24 -40 53 -82 30 -42 73 -105 96 -139 l43 -63 -29
-42 c-16 -24 -60 -87 -96 -140 -37 -54 -67 -100 -67 -103 0 -3 28 -5 63 -5
l63 0 63 95 c35 52 67 92 72 89 5 -3 36 -45 70 -94 l61 -90 65 0 65 0 -17 27
c-10 16 -29 43 -42 61 -49 66 -133 194 -133 203 0 5 28 50 63 100 34 50 77
113 94 140 l33 49 -68 0 -67 -1 -55 -85 c-30 -47 -60 -89 -65 -94 -6 -5 -35
27 -74 86 l-65 94 -63 0 c-35 0 -63 -2 -63 -6z"/>
					<path d="M3520 355 c0 -184 3 -335 6 -335 3 0 30 21 60 46 l54 46 0 289 0 289
-60 0 -60 0 0 -335z"/>
					<path d="M3736 683 c-8 -8 -7 -663 1 -663 3 0 30 21 59 46 l54 46 0 169 c0
112 3 168 10 164 6 -4 65 -72 132 -153 165 -199 150 -184 194 -180 l39 3 0
285 0 285 -57 3 -58 3 -2 -175 -3 -176 -50 61 c-27 34 -57 69 -65 79 -8 9 -51
61 -94 114 -74 89 -82 96 -116 96 -20 0 -40 -3 -44 -7z"/>
					<path d="M4351 602 l-44 -88 44 -85 44 -84 156 -3 c156 -2 156 -3 168 -28 9
-20 8 -31 -4 -54 l-16 -30 -154 0 -154 0 -41 -60 -41 -60 228 0 228 0 46 89
46 89 -45 86 -46 87 -146 -1 c-80 -1 -150 -1 -156 0 -6 0 -16 11 -22 25 -10
21 -9 32 3 55 l16 30 152 0 c169 0 156 -5 210 78 l27 42 -227 0 -228 -1 -44
-87z"/>
					<path d="M4930 684 c0 -3 17 -29 38 -59 l37 -54 60 -3 60 -3 0 -273 c0 -149 3
-271 7 -270 4 2 30 23 57 46 l51 44 0 229 0 229 63 0 63 0 29 50 c17 27 33 50
38 50 4 0 7 5 7 10 0 6 -92 10 -255 10 -140 0 -255 -3 -255 -6z"/>
					<path d="M5535 682 c-6 -4 -27 -33 -47 -64 l-37 -57 -3 -270 c-2 -149 -1 -271
1 -271 2 0 28 19 57 42 l53 43 1 88 0 87 125 0 125 0 0 -85 0 -85 60 0 60 0 0
223 0 222 -46 68 -46 67 -147 0 c-80 0 -151 -4 -156 -8z m278 -197 l0 -80
-126 -3 -127 -3 0 86 0 86 127 -3 126 -3 0 -80z"/>
					<path d="M6027 683 c-9 -8 -9 -663 -1 -663 4 0 31 21 60 46 l54 46 0 175 0
175 77 -94 c42 -51 79 -95 83 -98 3 -3 34 -40 69 -83 62 -75 65 -77 107 -77
l44 0 -2 288 -3 287 -57 3 -58 3 -2 -175 -3 -175 -145 174 c-138 166 -147 175
-181 175 -20 0 -39 -3 -42 -7z"/>
					<path d="M6641 630 l42 -60 58 0 59 0 0 -282 c0 -156 4 -278 8 -273 4 6 31 30
60 54 l52 43 0 229 0 229 58 0 c64 0 67 2 115 78 l27 42 -260 0 -261 0 42 -60z"/>
					<path d="M7530 400 l0 -290 198 0 197 0 22 53 c12 28 38 94 57 145 l36 92 -57
145 -57 145 -198 0 -198 0 0 -290z m351 95 c34 -87 34 -103 0 -190 l-27 -70
-104 0 -105 0 -3 155 c-1 85 0 160 3 167 3 11 29 13 107 11 l102 -3 27 -70z"/>
					<path d="M8167 619 l-46 -71 -1 -264 c0 -145 2 -264 5 -264 4 0 30 19 60 43
l54 42 0 88 1 87 125 0 124 0 3 -82 3 -83 58 -3 57 -3 0 221 0 222 -46 69 -47
69 -152 0 -153 0 -45 -71z m323 -134 l0 -85 -125 0 -125 0 0 85 0 85 125 0
125 0 0 -85z"/>
					<path d="M8650 630 l41 -60 59 0 60 0 0 -275 c0 -151 3 -275 6 -275 3 0 30 21
60 46 l55 47 -1 226 -2 226 61 3 61 3 40 59 41 60 -261 0 -261 0 41 -60z"/>
					<path d="M9176 620 l-46 -70 0 -265 c0 -146 3 -265 6 -265 4 0 31 21 60 46
l54 46 0 84 0 84 125 0 125 0 0 -85 0 -85 60 0 60 0 0 220 c0 219 -3 240 -37
277 -6 7 -21 28 -33 48 l-22 35 -152 0 -153 0 -47 -70z m324 -135 l0 -85 -125
0 -125 0 0 85 0 85 125 0 125 0 0 -85z"/>
					<path d="M1226 388 c-9 -29 -20 -59 -23 -66 -4 -10 29 -12 157 -10 l162 3 24
63 23 62 -163 0 -162 0 -18 -52z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
